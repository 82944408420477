import './style.css';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

let scene, renderer, camera, centre;
let calabi;

init();

// Setup

function init() {
  scene = new THREE.Scene();

  renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('#threejs-canvas'),
  });

  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);

  camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000);

  camera.position.setZ(1.5);
  centre = new THREE.Vector3( 0, 0, 0 );
  camera.lookAt(centre)
  
  /*
  function calabiCoordinate(s, n, a, k1, k2, x, y) {
    let z1 = math.multiply(math.exp(math.multiply(math.complex(0,1),2,math.pi,k1, 1/ n)), math.pow(math.cos(math.complex(x,y)), 2/n));
    let z2 = math.multiply(math.exp(math.multiply(math.complex(0,1),2,math.pi,k2, 1 / n)), math.pow(math.sin(math.complex(x,y)), 2/n));
    return new THREE.Vector3(math.multiply(s,z1.re), math.multiply(s,z2.re), math.multiply(s,z1.im*math.cos(a)+z2.im*math.sin(a)));
  }

  const calabi_vert = []
  const num = 8;
  const res = 10;
  for (let x=0; x < math.pi / 2; x += 1/res) {
    for (let y= -math.pi / 2; y < math.pi / 2; y += 1/res) {
      for (let k1 = 0; k1 < num; k1++) {
        for (let k2 = 0; k2 < num; k2++) {
          const position = calabiCoordinate(0.5,num,math.pi / 2,k1,k2,x,y)
          calabi_vert.push(position.x, position.y, position.z)
        }
      }
    }
  }

  const calabi_gen = new THREE.BufferGeometry();
  calabi_gen.setAttribute( 'position', new THREE.Float32BufferAttribute( calabi_vert, 3 ) );

  const calabi_mat = new THREE.PointsMaterial( { color: 0x00ffff, size: 0.001, sizeAttenuation: true } );

  calabi = new THREE.Points( calabi_gen, calabi_mat );
  scene.add( calabi );

  const loader = new OBJLoader();

  loader.load('models/calabi8.obj',
    function ( object ) {
      const calabi2 = new THREE.Points( object.geometry, calabi_mat );
      scene.add( calabi2 );
    }
  );
  */
  
  let cloud_material = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.0001, sizeAttenuation: true })

  const STLloader = new STLLoader()
  STLloader.load('models/calabi6.stl',
      (geometry) => {
          calabi = new THREE.Points(geometry, cloud_material)
          calabi.scale.set(1,1,1)
          scene.add(calabi)
      },
      (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
          console.log(error)
      }
  )
  
  window.addEventListener( 'resize', onWindowResize );
}

// Scroll Animation

function moveCamera() {
  const t = document.body.getBoundingClientRect().top;
  
  //camera.position.y = 1.5 * Math.cos(t * 0.0001)
  //camera.position.z = 1.5 * Math.sin(Math.abs(t * 0.0001))
  camera.position.setZ(1.5+t*0.0001);
  camera.lookAt(centre)
}

document.body.onscroll = moveCamera;
moveCamera();

function animate() {
  requestAnimationFrame(animate);

  if (calabi) {
    calabi.rotation.y += 0.0001;
    calabi.rotation.x += 0.0002;
  }

  renderer.render(scene, camera);
}

animate();

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
}